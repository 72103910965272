import React from "react";
import { Link } from "gatsby";
import { removePlus} from "../../components/utils";



const SpeakWithTeam = (props) => {
  const Mortgage_Person = props?.Name === 'Ehsan Jamshidzadeh' ? true : false;
  const Watsapp = props?.Watsapp ? props?.Watsapp : props?.Phone;
    return (
        <div class="contact-agent">
            {Mortgage_Person !=true ?
            <div class="contact-agent-photo">
                <img src={props?.Image} alt= {`${props?.Name},${props?.Designation} -Espace`} />
            </div>
             : ''}
            <div class="contact-agent-info">
                <span class="contact-agent-name">Speak with our Mortgage Experts Today</span>
                <div class="agent-num-wrp">                                
                    <Link href={`tel:${props?.Phone}`} className="contact-agent-number">{props?.Phone}
                    </Link>
                    <span className="divider">/</span> 
                    <a href={`https://api.whatsapp.com/send?phone=${removePlus(Watsapp)}&text=Hello%2C%20I%20would%20like%20to%20speak%20with%20a%20mortgage%20advisor%20`} target="_blank" rel="noreferrer" className="contact-agent-number watsapp-number"><i class="icon-whatsapp"></i>Whatsapp us</a>
                </div>
            </div>
        </div> 
    );
}
export default SpeakWithTeam;