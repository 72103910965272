import { Link, useStaticQuery, graphql } from "gatsby";
import React, {useState} from "react";
import { Button, Form } from "react-bootstrap";
import "./NewMortgageCalculator.scss";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from 'react-rangeslider'
import 'react-rangeslider/lib/index.css'
import { calculateMonthlyPayment } from "../../components/calculators/calculators";
import { filterNumber} from "../../components/utils";
import Minus from '../../images/minus.svg'
import Plus from '../../images/plus.svg'
import { useQuery } from "@apollo/client";
import gql from "graphql-tag"
import SpeakWithTeam from "./SpeakWithTeam";


const TEAM_DETAILS = gql`
    query teamDetails($email: String!){
        teams(where: {Email: $email }) {
            id
            Whatsapp_Number
            Name
            Phone
            Email
            Designation
            Area_of_Special
            URL
            Tile_Image {
                url
                alternativeText
            }
        }
    }
`;

const MortgageCalculator = (props) => {
    const datas = useStaticQuery(graphql`
        query {
            glstrapi {
                globalModule {
                    Rate_Intrest
                }
            }
        }
    `);
    const numberToRound = (roundOfNumber, decimals) => {
        return Number((Math.round(roundOfNumber + "e" + decimals) + "e-" + decimals));
    }

    var rateOfIntrest = datas?.glstrapi?.globalModule?.Rate_Intrest;
    const [monthlyRepayment, setMonthlyPayment] = useState(0);
    const [rateValue, setRateValue] = useState('0.25');
    const [content, setContent] = useState('');
    const [downPaymentPercent, setDownPaymentPercent] = useState('20');
    const [minRange, setMinRange] = useState('20');
    const [numLength, setNumLength] = useState('');
    //
    const [interestRate, setinterestRate] = useState(rateOfIntrest ? rateOfIntrest : 2.99)
    //check decimal count
    Number.countDecimals = function () {
        if(Math.floor(this.valueOf()) === this.valueOf()) return 0;
        return this.toString().split(".")[1].length || 0; 
    }


    const handleChangeInterestRate = value => {
        if (value <= Number(20) && value >= Number(0)) {
            setNumLength(parseInt(value))
            if (value.length > 1) {
                setRateValue('')
            }
            if (value.length === 1) {
                setRateValue(0.25)
            }
            setinterestRate(value)//limit floating digit to 2
            setContent('')
        } else {
            setContent('The interest rate is usually set between 0.25% and 20.00%')
        }
        if (value < Number(0.25)) {
            setContent('The interest rate is usually set between 0.25% and 20.00%')
        }
    };

    const blurOut = (value) => {
        if (value < Number(0.25) && value >= Number(0)) {
            setinterestRate(0.25)
            setContent('')
        }
    }

    // percentage convert
    function percentage(a, b) {
        var c = (parseFloat(a) * parseFloat(b)) / 100
        return parseFloat(c)
    }
  
    const [downPayment, setdownPayment] = useState(0);

    const [loanValue, setloanValue] = useState( (props?.property?.price )? props?.property?.price : 3000000);
    const handleChangeDownPayment = value => {
        setDownPaymentPercent(value)
        setdownPayment(percentage(loanValue , value))
    };


    const handleChangeLoanValue = value => {
        setloanValue(value)        
        checkDownPayment(value);       
    };

    const [duration, setduration] = useState(10)
    const handleChangeduration = value => {
        setduration(value)
    };

    const handleSubmit = () => {
        let finalValue; 
        if (rateValue) {
            finalValue = rateValue
        } else if (interestRate >= 0 && interestRate <= 0.25) {
            finalValue = 0.25
        }
        if (interestRate > 0.25) {
            finalValue = interestRate
        }

        setMonthlyPayment(
            calculateMonthlyPayment(
                parseInt(filterNumber(loanValue)),
                parseFloat(finalValue === '0' ? 0.25 : finalValue),
                parseInt(filterNumber(downPayment)),
                duration
            )
        );
    }

    const checkDownPayment = (value) => {
        var valueData = value ? value : loanValue
        // var downPaymentPercent = 0;
        // if(valueData <= 5000000){
        //     downPaymentPercent= 20;
        // }else{
        //     downPaymentPercent= 30;
        // }
        if(value > 5000000 ){ 
            setMinRange(30);
            setDownPaymentPercent(30);
         }else {
            setMinRange(20);
            //setDownPaymentPercent(20);
         }
        var downpaymentVal = Math.round(downPaymentPercent/100 * valueData);
        setdownPayment(downpaymentVal)

    }

    React.useEffect(() => {
        handleSubmit();
       
    }, [loanValue, interestRate, duration, downPayment])

    React.useEffect(() => {
        if(props?.property) {
            setloanValue(props?.property?.price)
        }
        checkDownPayment()
        handleSubmit();
    }, []);

     const increment = () => {
        let value = Number(interestRate) + Number(0.10)
        if (value > 20) {
            setContent('The interest rate is usually set between 0.25% and 20.00%')
        } else {
            setContent('')
        }
        if (Number(interestRate) > 19.90) {
            setinterestRate(20)
            setContent('')
        } else {
            setinterestRate(parseFloat(value).toFixed(2))
        }
    }
    const decrement = () => {
        let value = Number(interestRate) - Number(0.10)
        if (parseFloat(value).toFixed(2) < '0.25') {
            setContent('The interest rate is usually set between 0.25% and 20.00%')
        } else {
            setContent('')
        }
        if (Number(interestRate) < 0.35) {
            setinterestRate(0.25)
            setContent('')
        } else {
            setinterestRate(parseFloat(value).toFixed(2))   
        }
    }
    const numberFormat = num => {
        if (!num) return 0
        return new Intl.NumberFormat("en-US", {}).format(num)
    }
    const { loading, error, data } = useQuery(TEAM_DETAILS, {
        variables: { email: props?.email }
    });
  
    const formatPc = p => p + '%'
    return (
        <React.Fragment>
            <div className="mortgage-calculator mc-wrapper">
                <ScrollAnimation animateIn="fadeInLeft" animateOnce>
                <div className="mortgage-form-top">  
                <h4>Calculate your Mortgage</h4>
                       
                <Form.Group controlId="exampleForm.ControlInput1" className="prices">
                    <Form.Label>Property Price:</Form.Label>
                    <div className="input-wrp">
                        <Form.Control
                            type="text"
                            placeholder="Enter your price"
                            value={numberFormat(loanValue)}
                            onChange={(e) => {
                                const val = e.target.value.replaceAll(",", "");
                                const re = /^[0-9\b]+$/;
                                if (re.test(val)) {
                                    handleChangeLoanValue(val)
                                } else {
                                    handleChangeLoanValue("")
                                }
                            }}
                        />
                    <span className="unit-txt">AED</span>
                    </div>
                </Form.Group>
              

                <Form.Group>
                    <Form.Label>
                        <div className="form-label-wrapper">
                            <div>Down Payment:</div>
                            <div className="text-center mortgage-values down-payment">{ downPayment > 0 && downPayment?.toLocaleString()+" AED"}</div>
                        </div>
                    </Form.Label>
                    <div className='slider custom-labels mortgage-slider-custom down-pay-slider'>
                    <Slider
                        min={minRange}
                        max={80}
                        step={1}
                        tooltip={false}
                        format={formatPc}
                        value={downPaymentPercent}
                        handleLabel={downPaymentPercent + '%'}
                        onChange={handleChangeDownPayment}
                    />                   
                    </div>
                </Form.Group>

                {/* <Form.Group className="d-none our-counter-values">
                    <Form.Label>Loan to Value:</Form.Label>
                    <div className='slider custom-labels mortgage-slider-custom'>
                        <Slider
                            min={0}
                            max={100}
                            value={loanValue}
                            handleLabel={loanValue}
                            onChange={handleChangeLoanValue}
                        />
                        <div className="text-center mortgage-values">{loanValue+" %"}</div>
                    </div>
                </Form.Group> */}

                <Form.Group>
                    <Form.Label>
                        <div className="form-label-wrapper">
                            <div>Duration:</div>
                            <div className="mortgage-values">{duration+" Years"}</div>
                        </div>
                    </Form.Label>
                    <div className='slider custom-labels mortgage-slider-custom'>
                        <Slider
                            min={1}
                            max={25}
                            value={duration}
                            handleLabel={duration}
                            onChange={handleChangeduration}
                        />                        
                    </div>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Interest Rate:</Form.Label>
                    {/* <span className="range-slider">
                    <span className="counter"></span>
                    </span> */}
                    <div className='slider custom-labels mortgage-slider-custom'>
                        <div className="interest-rate-sec mb-2">
                        <div className="input-wrp rate-field">
                            <Form.Control
                                className="rate-field-text"
                                id="pricetext"
                                min="0" max="20"
                                type="text"
                                maxLength={numLength >= 10 ? '5' : '4'}
                                placeholder={rateValue}
                                value={interestRate}
                                onChange={(e) => {
                                const val = e.target.value
                                    handleChangeInterestRate(val)
                                }}
                                onBlur={(e) => blurOut(e.target.value)}
                            />
                            <span className="unit-txt">%</span>
                        </div>
                        <div className="btnn-secc">
                            <Button onClick={decrement} disabled={Number(interestRate > 0.25) ? false : true}><img src={Minus} alt="button" /></Button>
                            <Button onClick={increment} disabled={Number(interestRate < 20) ? false : true}><img src={Plus} alt="button" /></Button>
                        </div>
                    </div>
                    {content && <small className="text-danger">{content}</small>}
                    </div>
                </Form.Group>
                </div>
                <div className="form-bottom">
                    <Form.Group className="monthly-pay">
                        <Form.Label className="price-label">Monthly Payment:</Form.Label>
                        <span className="price-txt">AED {monthlyRepayment.toLocaleString()}</span>
                    </Form.Group>
                    {props.team  && 
                        <SpeakWithTeam Image={props.team?.Tile_Image?.url} Name={props.team?.Name} Designation ={props.team?.Designation} Phone={props.team?.Phone}  />
                    }
                    {(props.calculator && props.calculator.Speak_with_Team.length > 0) && 
                        <SpeakWithTeam Image={props.calculator.Speak_with_Team[0]?.Tile_Image?.url} Name={props?.calculator?.Speak_with_Team[0]?.Name} Designation ={props?.calculator?.Speak_with_Team[0]?.Designation} Phone={props.calculator.Speak_with_Team[0]?.Phone}  />
                    }
                    {(data && data.teams.length > 0) &&
                        <SpeakWithTeam Image={data?.teams[0]?.Tile_Image?.url} Name={data?.teams[0]?.Name} Designation ={data?.teams[0]?.Designation} Phone={data?.teams[0]?.Phone} Watsapp={data?.teams[0]?.Whatsapp_Number} />                    
                    }             
                </div>
                </ScrollAnimation>
            </div>
        </React.Fragment>
    );
};
export default MortgageCalculator;